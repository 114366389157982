
















































































































import Vue, { VueConstructor } from 'vue';
import { ApolloError } from 'apollo-client';
import Spinner from '@/components/Spinner.vue';
import { COLLECTIONS_QUERY, SLOT_QUERY } from '../queries/query';
import { Table, TableColumn } from 'element-ui';
import { CollectionType, CollectionSlot } from '../model/slot';
import { apolloClient } from '@/apolloClient';
import {
  Collection,
  CollectionConnection,
  CollectionVisibility,
} from '../model/collection';
import draggable from 'vuedraggable';
import { CommonState } from '@frientrip/domain';
import { CollectionService } from '../service/CollectionService';
import { getDomainName } from '@/env';

type ViewCollection = Omit<Collection, 'status'> & { status: boolean };

const convertStatusToBoolean = (status: CommonState): boolean => {
  return status === CommonState.ACTIVE ? true : false;
};
const collectionService = new CollectionService(apolloClient);

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'CollectionProductList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
    draggable,
  },
  data() {
    return {
      slotId: this.$route.params.slotId,
      collections: [] as ViewCollection[],
      loading: 0,
      isAnyChanged: false,
      slot: { maximumCountOfCollection: 0 },
    };
  },
  computed: {
    maximumCountOfCollection(): number {
      const result = this.slot?.maximumCountOfCollection || 0;
      return result;
    },
  },
  activated: async function () {
    await this.$apollo.queries.collections.refetch();
  },
  methods: {
    openProductCollectionPage(id: number): void {
      // https://next.frip.co.kr/collections/ad?collectionId=172
      window.open(
        `${getDomainName()}/collections/ad?collectionId=${id}`,
        '_blank'
      );
    },
    async updateVisibleStates(): Promise<void> {
      this.$modal.show(
        {
          title: '변경사항을 저장하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          try {
            const params: CollectionVisibility[] = this.collections.map(
              ({ id, status, isRandomOrder }) => ({
                id,
                home: status,
                listing: status,
                randomOrder: isRandomOrder,
              })
            );
            await collectionService.updateCollectionVisibleStates(
              this.slotId,
              params
            );
            this.$apollo.queries.collections.refetch();
            this.isAnyChanged = false;
            this.showSuccessMessage('변경 되었습니다.');
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
          }
        }
      );
    },
    isAnyChangedType(): string {
      return this.isAnyChanged ? 'primary' : 'secondary';
    },
    updatedCollectionSortEvent(): void {
      let seqNo = 1;
      this.isAnyChanged = true;
      this.collections.forEach((collection: ViewCollection) => {
        if (collection.status) {
          collection.seqNo = seqNo;
          seqNo += 1;
        } else {
          collection.seqNo = 0;
        }
      });
    },
    updateStatus(): void {
      this.updatedCollectionSortEvent();
      this.isAnyChanged = true;
    },
    updateIsRandomOrder(): void {
      this.isAnyChanged = true;
    },
    getRowClass(status: boolean): string {
      let result = '';
      if (status === false) {
        result = 'gray';
      } else {
        result = 'white';
      }
      return result;
    },
    getTitleType(): string {
      const collectionType = this.$attrs.CollectionType as CollectionType;
      return collectionType === CollectionType.PRODUCT ? '상품' : '테마';
    },
    showErrorMessage(message: string): void {
      this.$notify({
        duration: 5,
        title: '실패',
        message: message.replace('GraphQL error:', ''),
        type: 'warning',
      });
    },
    showSuccessMessage(message: string): void {
      this.$notify({
        duration: 5,
        title: '성공',
        message: message,
        type: 'success',
      });
    },
  },
  apollo: {
    collections: {
      query: COLLECTIONS_QUERY,
      variables(): { slotId: string; page: number; pageSize: number } {
        return { slotId: this.slotId, page: 1, pageSize: 1000 };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update(data: {
        collection: { collections: CollectionConnection };
      }): ViewCollection[] {
        const result = data.collection.collections.edges.map(
          ({ node: { status, ...rest } }) => ({
            status: convertStatusToBoolean(status),
            ...rest,
          })
        );
        return result;
      },
    },
    slot: {
      query: SLOT_QUERY,
      variables(): { id: string } {
        return {
          id: this.slotId,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update: (data: {
        collection: { slot: CollectionSlot };
      }): Omit<CollectionSlot, 'collections' | 'countOfVisibleCollections'> => {
        // query result must be deep copy = implicit caching
        return JSON.parse(JSON.stringify(data.collection.slot));
      },
    },
  },
});
